<template>
<div class="orderTrack">
  <Trips :details="tripDetails"/>
</div>
</template>
<script>
import Trips from '@/modules/clue/component/trips/index.vue'
import clueServices from '@/services/clueServices'
export default {
  name: 'orderTrack',
  components:{ Trips },
  props:{
    orderDetail: {
      type: Object,
      default: ()=>({})
    }
  },
  data() {
    return {
      tripDetails: {}
    }
  },
  mounted(){
    this.getTrips()
  },
  methods: {
    // 获取轨迹
    async getTrips(){
      const params = { 
        id: this.orderDetail.leadId,
        orderId: this.orderDetail.id,
        type: 'ORDER'
      }
      const res = await clueServices.getTrips(params)
      this.tripDetails = res
    },
  }
}
</script>
<style lang="less" scoped>
.orderTrack{
}
</style>
