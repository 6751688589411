<template>
<div class="vehicleLogistics">
  {{$t('开发中。。。')}}
</div>
</template>
<script>
export default {
  name: 'vehicleLogistics',
  data() {
    return {

    }
  },
}
</script>
<style lang="less" scoped>
.vehicleLogistics{
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
