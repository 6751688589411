<template>
<div class="customerInfo">
  <template v-if="showCustomerInfo">
    <div class="common-t">
      <div class="common-t-l">{{$t('下单人信息')}}</div>
      <div class="common-t-r" @click="improveUser">
        <!-- <van-icon name="edit" size="16" /> -->
        {{$t('查看下单人信息')}}
      </div>
    </div>
    <van-cell :title="$t('姓名')" :value=orderDetail.customerName />
    <van-cell :title="$t('电话')" :value=orderDetail.customerMobile />
  </template>
  <!-- 来自交车 或者当前购车类型为个人 后续交车也会去人个人或者企业 -->
  <template v-if="orderDetail.customerType == 1">
    <div class="common-t">
      <div class="common-t-l">{{$t('车主信息')}}</div>
    </div>
    <van-cell :title="$t('姓名')" :value=orderDetail.carRegistration?.ownerName />
    <van-cell :title="$t('电话')" :value=orderDetail.carRegistration?.mobile />
    <van-cell :title="$t('证件类型')" :value=orderDetail.carRegistration?.idTypeName />
    <van-cell :title="$t('证件号码')" :value=orderDetail.carRegistration?.idNum />
    <van-cell :title="$t('上牌城市')" :value=orderDetail.carRegistration?.cityName />
  </template>
  <template v-else>
    <div class="common-t">
      <div class="common-t-l">{{$t('企业信息')}}</div>
    </div>
    <van-cell :title="$t('企业名称')" :value=orderDetail.carRegistration?.ownerName />
    <van-cell :title="$t('电话')" :value=orderDetail.carRegistration?.mobile />
    <van-cell :title="$t('统一社会信用代码')" :value=orderDetail.carRegistration?.idNum />
    <van-cell :title="$t('联系人')" :value=orderDetail.carRegistration?.contactPerson />
  </template>
</div>
</template>
<script>
export default {
  name: 'customerInfo',
  props: {
    orderDetail: {
      type: Object,
      default: () => ({})
    },
    // fromDeliver: {
    //   type: Boolean,
    //   default: () => { false }
    // },
    showCustomerInfo: {
      type: Boolean,
      default: () => (true)
    }
  },
  data() {
    return {}
  },
  mounted() { },
  methods: {
    improveUser() {
      if (this.orderDetail.leadTransferFlag===1){
        return this.$toast(this.$t('线索已转出至其他门店，无法查看'))
      }
      this.$router.push({
        path: '/clue-details',
        query: {
          id: this.orderDetail.leadId,
        },
      })
    },
  }
}
</script>
<style lang="less" scoped>
.customerInfo {
  .icons {
    display: flex;
    padding: 8px 0 12px 0;
    justify-content: flex-end;

    .icon {
      margin-left: 16px;
    }
  }
}
</style>
