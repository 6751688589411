<!-- 金融详情 -->
<template>
<div class="finance">
  <van-cell :title="$t('金融机构')" :value="orderDetail.orderFinance?.financeOrg"></van-cell>
  <!-- <van-cell :title="$t('金融单号')" :value="orderDetail.orderFinance?.financeOrderId"></van-cell> -->
  <van-cell :title="$t('申请时间')" :value="orderDetail.orderFinance?.orderCreatedTime"></van-cell>
  <van-cell :title="$t('订单状态')" :value="orderDetail.orderFinance?.financeStatus | codeFormat"></van-cell>
  <van-cell :title="$t('产品类型')" :value="orderDetail.orderFinance?.productType | productTypeFilter"></van-cell>
  <!-- <van-cell :title="$t('产品名称')" :value="orderDetail.orderFinance?.campaignName"></van-cell> -->
  <!-- <van-cell :title="$t('银行授权')" :value="orderDetail.orderFinance?.bankAuthStatus ? $t('已授权') : $t('未授权')"></van-cell> -->
  <!-- <van-cell :title="$t('合同签署')" :value="orderDetail.orderFinance?.contractSignStatus ? $t('已签署') : $t('未签署')"></van-cell> -->
  <van-cell :title="$t('首付金额')">
    <span v-if="formatRMB(orderDetail.orderFinance?.downPaymentAmount)" content="¥"></span>{{ formatRMB(orderDetail.orderFinance?.downPaymentAmount) }}
  </van-cell>
  <van-cell :title="$t('贷款金额')">
    <span v-if="formatRMB(orderDetail.orderFinance?.loanAmount)" content="¥"></span>{{ formatRMB(orderDetail.orderFinance?.loanAmount) }}
  </van-cell>
  <van-cell :title="$t('贷款期限')" :value="orderDetail.orderFinance?.tenor"></van-cell>
  <template v-if="issShowMore">
    <van-cell :title="$t('月供')">
      <span v-if="formatRMB(orderDetail.orderFinance?.installmentAmount)" content="¥"></span>{{ formatRMB(orderDetail.orderFinance?.installmentAmount) }}
    </van-cell>
    <van-cell :title="$t('总利息')">
      <span v-if="formatRMB(orderDetail.orderFinance?.favorableInterest)" content="¥"></span>{{ formatRMB(orderDetail.orderFinance?.favorableInterest) }}
    </van-cell>
    <van-cell :title="$t('贴息金额')">
      <span v-if="formatRMB(orderDetail.orderFinance?.subsidy)" content="¥"></span>{{ formatRMB(orderDetail.orderFinance?.subsidy) }}
    </van-cell>
  </template>
  <!-- <p class="showMore" @click="showMore">{{ issShowMore ? $t('收起') : $t('更多') }}</p> -->
  <!-- <van-button v-if="isShowBtns" class="btn-common btn" @click="onClick">{{$t('提交金融单撤销申请')}}</van-button> -->
</div>
</template>
<script>
import loading from '@/utils/loading'
import deliveryServices from '@/services/deliveryServices.js'

export default {
  name: 'finance',
  filters: {
    productTypeFilter(val) {
      if (!val) return ''
      return {
        1: 'Classic',
        2: 'Balloon ',
        3: 'Structure',
      }[val]
    }
  },
  props: {
    orderDetail: {
      type: Object,
      default:() => ({})
    }
  },
  data() {
    return {
      issShowMore: true,
    }
  },
  computed:{
    isShowBtns(){
      // 放款后则不能提交申请
      return !['40050012', '40050013', '40050014', '40050015'].includes(this.orderDetail.orderFinance?.financeStatus) 
    }
  },
  methods: {
    onClick(){
      this.$dialog.confirm({
        title: this.$t('确认撤销当前订单的金融申请'),
        message: this.$t('请确认订单信息是否准确，金融订单将在购车人确认后进行撤销 ？'),
        beforeClose: async (action, done)=>{
          done()
          if (action === 'confirm') {
            try {
              loading.showLoading()
              await deliveryServices.cancelFinance( this.orderDetail.orderFinance.id)
              loading.hideLoading()
              this.$toast.success(this.$t('请求成功'))
              // this.$emit('refresh')
            } catch (error) {
              loading.hideLoading()
              // this.$toast.fail(this.$t('请求失败，请联系管理员') )
            } 
          }
        }
      })
    },
    showMore() {
      this.issShowMore = !this.issShowMore
    }
  }
}
</script>
<style lang="less" scoped>
.finance{
  /deep/ .van-cell{
    padding: 10px 0;
    &::after{
      width: 100%;
      left: 0;
    }
  }
  .btn{
    margin-top: 16px;
  }
  .showMore{
    line-height: 45px;
    text-align: center;
    color: @yellow-text;
  }
}
</style>
